@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	html {
		font-family: 'DM Sans', sans-serif !important;
		font-feature-settings: 'kern' !important;
		-webkit-font-smoothing: antialiased;
		letter-spacing: -0.5px;
	}
}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg); 
	color: white;
	opacity: 0; 
	height: 16px; 
	width: 16px;
	position: absolute; 
	left: 50%;
	transform: translate(-50%, 0px);
} 

input:checked.defaultCheckbox::before { 
	opacity: 1;
}

:root {

    --theme-font-color-black: #13072e;
    --theme-bg-color: #13072e;
    --theme-bg-color-black: #3f2182;
    --theme-bg-color-blue:#48358f;
    --theme-bg-color-pink:#9a72c1;
}

.text-brand-500{
	color:  var(--theme-bg-color-black);
}

.bg-brand-500 , .bg-brand-900 , .bg-brand-600{
	background: var(--theme-bg-color-black);
}

.bg-brand-800:hover ,.bg-brand-900:hover , .bg-brand-600:hover{
	background: var(--theme-bg-color-blue);
}

.hover\:bg-brand-600:hover{
	background: var(--theme-bg-color-blue) !important;
}

.theme-input ,.theme-input-link{
    min-width: 220px;
}

.theme-input-link{
	background: var(--theme-bg-color-blue);
    color: #FFF;
    padding: 0.5rem;
    border-radius: 0.3em;
}